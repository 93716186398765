.redactor-air,
.redactor-toolbar,
.redactor-dropdown,
.redactor-modal {
    font-family: inherit;
}

.redactor-air {
    padding: 0.375rem;
}

.redactor-box .CodeMirror {
    border: 0;
}

.redactor-box {
    border: 1px solid var(--color-background-tertiary);
    border-radius: 0.25rem;
    z-index: 1;
}

.sp-redactor-rounded-none .redactor-box {
    border-radius: 0;
}

.sp-redactor-rounded-t-none .redactor-box {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.sp-redactor-rounded-b-none .redactor-box {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.redactor-in,
.redactor-read-only {
    background: var(--color-background-primary);
    border-radius: 0.25rem;
    padding: 0.75rem;
}

.redactor-toolbar-wrapper + .redactor-in,
.redactor-toolbar-wrapper + .redactor-read-only {
    border-radius: 0 0 0.25rem 0.25rem;
}

.sp-redactor-rounded-t-none .redactor-in,
.sp-redactor-rounded-t-none .redactor-read-only {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.sp-redactor-rounded-b-none .redactor-in,
.sp-redactor-rounded-b-none .redactor-read-only {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.sp-redactor-bg-orange .redactor-in,
.sp-redactor-bg-orange .redactor-read-only {
    background: #fffaf0;
}

.sp-theme-dark .sp-redactor-bg-orange .redactor-in,
.sp-theme-dark .sp-redactor-bg-orange .redactor-read-only {
    background: #7b341e;
}

.sp-redactor-bg-purple .redactor-in,
.sp-redactor-bg-purple .redactor-read-only {
    background: #faf5ff;
}

.sp-theme-dark .sp-redactor-bg-purple .redactor-in,
.sp-theme-dark .sp-redactor-bg-purple .redactor-read-only {
    background: #44337a;
}

.redactor-read-only {
    opacity: 0.75;
}

.redactor-toolbar-wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.sp-quick-actions ~ .sp-content-inner .redactor-toolbar-wrapper,
.sp-alert-sticky:not(.sp-hidden) ~ .sp-content-inner .redactor-toolbar-wrapper {
    top: 3rem;
}

.redactor-toolbar {
    background: var(--color-background-secondary);
    border-bottom: 1px solid var(--color-background-tertiary);
    line-height: 1.5 !important;
    padding: 0 0.25rem;
}

.redactor-air a,
.redactor-toolbar a {
    background: 0;
    box-shadow: none;
    margin: 0;
}

.redactor-toolbar a {
    color: var(--color-text-primary);
}

.redactor-air a .fa {
    color: #fff;
}

.redactor-air a.re-button-icon,
.redactor-toolbar a.re-button-icon {
    font-size: 0.875rem;
    padding: 0.375rem;
}

.redactor-toolbar a:hover,
.redactor-toolbar a.redactor-button-active,
.redactor-toolbar a.active,
.redactor-air a:hover,
.redactor-air a.redactor-button-active,
.redactor-air a.active {
    background: 0;
}

.redactor-toolbar a:hover,
.redactor-toolbar a:hover .fa,
.redactor-toolbar a.redactor-button-active,
.redactor-toolbar a.redactor-button-active .fa,
.redactor-toolbar a.active,
.redactor-toolbar a.active .fa {
    color: var(--color-text-secondary);
}

.redactor-toolbar a.redactor-button-disabled:hover,
.redactor-toolbar a.redactor-button-disabled:hover .fa {
    color: var(--color-text-primary);
}

.redactor-dropdown {
    background: var(--color-background-primary);
    border-radius: 0 0 0.25rem 0.25rem;
    border: 1px solid var(--color-background-tertiary);
    border-top: 0;
    box-shadow: none;
    font-size: 0.875rem;
    margin: -1px 0 0 -5px;
    max-height: 40vh;
    overflow: auto;
    width: auto;
    z-index: 10001;
}

.redactor-dropdown a {
    border-bottom: 0;
    color: var(--color-text-primary);
    padding: 0.625rem;
}

.redactor-dropdown a:hover,
.redactor-dropdown a.active,
.redactor-dropdown a .active:hover {
    background-color: var(--color-background-tertiary) !important;
    color: var(--color-text-primary) !important;
}

.redactor-dropdown a > span {
    display: flex;
}

.redactor-dropdown a span {
    padding: 0;
}

.redactor-dropdown a span i {
    align-self: center;
    margin-right: 0.5rem;
}

.redactor-dropdown a span .sp-icon-name {
    align-self: center;
    flex-direction: column;
    -moz-box-flex: 1;
    flex-grow: 1;
    line-height: 1 !important;
    margin: 0;
}

.redactor-dropdown a span.sp-icon-shortcut {
    align-self: center;
    background-color: var(--color-background-secondary);
    border-radius: 0.25rem;
    color: var(--color-text-secondary);
    font-size: 0.625rem;
    margin: -0.125rem 0 -0.125rem 0.7rem;
    padding: 0.25rem 0.375rem;
}

.redactor-dropdown-hr {
    width: auto;
    padding: 0;
    clear: both;
}

.redactor-dropdown-hr a {
    float: left;
}

.redactor-button-separator {
    display: inline;
    border-left: 1px solid var(--color-background-tertiary);
    margin: 0 0.25rem;
    padding: 0.375rem 0;
    vertical-align: middle;
}

.redactor-air .redactor-button-separator {
    border-left-color: rgba(255,255,255,.2);
}

.redactor-context-toolbar,
.re-button-tooltip {
    background: #212121;
    color: #fff;
    font-family: var(--font-display);
    z-index: 10010;
}

.re-button-tooltip:after {
    color: transparent;
}

.re-button-tooltip .sp-icon-shortcut {
    color: #fff;
    font-size: 0.75rem;
    opacity: 0.7;
}

.re-fontcolor-cells span {
    border-color: var(--color-background-primary) !important;
    float: left;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
}

.re-fontcolor-cells span:hover {
    border: 1px solid black !important;
}

.re-fontcolor-cells .active {
    border: 1px solid black !important;
    position: relative;
}

.re-fontcolor-cells .active:before {
    content: "\f00c";
    font-family: var(--font-icon);
    font-size: 1rem;
    font-weight: bold;
    left: 0.125rem;
    position: absolute;
    top: -0.125rem;
}

.redactor-statusbar {
    background: var(--color-background-tertiary);
    font-family: var(--font-code);
    padding: 0.375rem 0;
}

.redactor-statusbar li {
    color: var(--color-text-primary);
}

/* Redactor plugins (modals) */

.redactor-modal-box {
    background: rgba(0,0,0,0.4);
    font-family: var(--font-display);
    line-height: inherit;
    z-index: 10010;
}

.redactor-modal {
    background: var(--color-background-primary);
    box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
    color: var(--color-text-primary);
}

.redactor-modal.custom-redactor-plugin {
    height: calc(100% - 40px);
}

.redactor-modal-header {
    border-bottom: 1px solid var(--color-background-tertiary);
    color: var(--color-text-primary);
    font-size: 1.25rem;
    line-height: 2rem;
    padding: 0.75rem 1.5rem;
}

.redactor-modal .redactor-close {
    color: var(--color-text-primary);
    top: 12px;
}

.redactor-modal.custom-redactor-plugin .redactor-modal-body {
    height: calc(100% - 57px);
    overflow: auto;
    padding: 1.5rem;
}

.redactor-modal-body input:not([type="checkbox"]):not([type="radio"]),
.redactor-modal-body select,
.redactor-modal-body textarea {
    appearance: none;
    max-width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-color: var(--color-background-secondary);
    color: var(--color-text-primary);
    border-width: 2px;
    border-color: var(--color-background-secondary);
    border-radius: 0.25rem;
    line-height: 1.5;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-sizing: border-box;
    min-height: 2.5rem;
}

.redactor-modal-body input[type="checkbox"],
.redactor-modal-body input[type="radio"] {
    background-color: var(--color-background-tertiary);
    border: 0;
    bottom: 0;
}

.redactor-modal-body select {
    padding-right: 2rem;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
    background-position: right 0.125rem center;
    background-size: 1.5rem;
}

.redactor-modal input:hover,
.redactor-modal select:hover,
.redactor-modal textarea:hover {
    background-color: inherit;
    border-color: var(--color-background-secondary);
    box-shadow: inherit;
}

.redactor-modal input:focus,
.redactor-modal select:focus,
.redactor-modal textarea:focus {
    background-color: var(--color-background-primary);
    border-color: var(--color-background-secondary);
    box-shadow: inherit;
    outline: none;
}

.redactor-modal label {
    color: var(--color-text-primary);
}

.redactor-modal button {
    background: var(--color-primary-800);
    border: 0;
    color: #fff;
}

.redactor-modal button:hover {
    background: var(--color-primary-900);
    border: 0;
    color: #fff;
}

.redactor-modal button.redactor-button-danger, .redactor-modal button.redactor-button-unstyled {
    background: var(--color-background-secondary);
    color: var(--color-text-primary);
}

.redactor-modal button.redactor-button-danger:hover, .redactor-modal button.redactor-button-unstyled:hover {
    background: var(--color-background-tertiary);
    color: var(--color-text-primary);
    text-decoration: none;
}

.redactor-modal-footer {
    border-top-color: var(--color-background-tertiary);
}

.redactor-modal-body .sp-loading:after {
    animation: fa-spin 2s infinite linear;
    color: var(--color-text-secondary);
    content: "\f110";
    display: block;
    font-family: var(--font-icon);
    font-weight: bold;
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    z-index: 50;
}

.upload-redactor-box {
    background: var(--color-background-primary);
}

.upload-redactor-placeholder {
    color: var(--color-text-primary);
}

ul.redactor-search {
    border: 1px solid var(--color-background-tertiary);
    border-radius: 0.25rem;
    list-style: none;
    margin: 0;
    margin-top: 1.5rem;
    overflow-y: auto;
    padding: 0;
}

ul.redactor-search li {
    border-bottom: 1px solid var(--color-background-secondary);
}

ul.redactor-search li:last-child {
    border-bottom: 0;
}

ul.redactor-search li a {
    display: block;
    padding: 0.25rem 0.5rem;
}

ul.redactor-search li.no-results {
    padding: 0.25rem 0.5rem;
}

ul.redactor-search li a:hover {
    background: var(--color-background-secondary);
}

ul.redactor-search li .sp-title {
    font-weight: bold;
}

.redactor-modal-body ul li.sp-active {
    background: var(--color-primary-200);
}

.redactor-modal-body ul li.sp-disabled {
    opacity: 0.5;
}

.redactor-in p {
    margin: 0; /* act like breaklines */
}