input.datepicker,
input.timepicker {
    font-family: var(--font-icon-with-body);
}

.flatpickr-input[readonly] {
    opacity: 1;
}

.flatpickr-input[readonly]:focus {
    background: var(--color-background-primary);
}

.flatpickr-calendar {
    background: var(--color-background-primary);
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.sp-theme-dark .flatpickr-calendar {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
    border-bottom-color: transparent;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
    border-top-color: transparent;
}

.flatpickr-months {
    background: var(--color-background-secondary);
    border-radius: 0.25rem 0.25rem 0 0;
}

.flatpickr-months .flatpickr-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    height: 2.5rem;
}

.flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
    color: var(--color-text-primary);
    fill: var(--color-text-primary);
    padding: 0.825rem;
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
    color: var(--color-text-primary);
    fill: var(--color-text-primary);
}

.sp-theme-dark .flatpickr-months .flatpickr-next-month,
.sp-theme-dark .flatpickr-months .flatpickr-prev-month,
.sp-theme-dark .flatpickr-months .flatpickr-next-month:hover svg,
.sp-theme-dark .flatpickr-months .flatpickr-prev-month:hover svg {
    color: #fff;
    fill: #fff;
}

.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
    background-color: var(--color-background-tertiary);
}

.flatpickr-months .flatpickr-next-month:hover {
    border-radius: 0 0.25rem 0 0;
}

.flatpickr-months .flatpickr-prev-month:hover {
    border-radius: 0.25rem 0 0 0;
}

.flatpickr-current-month {
    font-size: 1rem;
    padding: 0;
}

.flatpickr-calendar select.flatpickr-monthDropdown-months,
.flatpickr-calendar .flatpickr-current-month input.cur-year {
    color: var(--color-text-primary);
    font-weight: 600;
    padding: .375rem .75rem;
}

.flatpickr-calendar select.flatpickr-monthDropdown-months {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: var(--color-background-secondary);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
    background-position: right 0.125rem center;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    border: 0;
    border-radius: 0;
    box-sizing: border-box;
    line-height: 1.5;
    padding-right: 2rem;
}

.flatpickr-calendar select.flatpickr-monthDropdown-months,
.flatpickr-current-month select.flatpickr-monthDropdown-months:hover {
    background-color: var(--color-background-secondary);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
    background-position: right 0.125rem center;
    background-repeat: no-repeat;
    background-size: 1.5rem;
}

.flatpickr-calendar select.flatpickr-monthDropdown-months option.flatpickr-monthDropdown-month {
    background-color: var(--color-background-secondary);
    color: var(--color-text-primary);
}

.flatpickr-current-month select.flatpickr-monthDropdown-months:hover,
.flatpickr-calendar .flatpickr-current-month input.cur-year:hover {
    background-color: rgba(0,0,0,.05);
}

.flatpickr-current-month .numInputWrapper {
    width: 5rem;
}

.flatpickr-calendar .numInputWrapper span.arrowUp:after {
    border-bottom-color: var(--color-text-secondary);
}

.flatpickr-calendar .numInputWrapper span.arrowDown:after {
    border-top-color: var(--color-text-secondary);
}

.flatpickr-weekdays {
    background: var(--color-background-secondary);
    border-top: 1px solid var(--color-background-primary);
}

span.flatpickr-weekday {
    color: var(--color-text-secondary);
    font-weight: 600;
}

.flatpickr-day {
    color: var(--color-text-primary);
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
    background: var(--color-primary-900);
    border-color: var(--color-primary-900);
    color: #fff;
}

.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.today.inRange, .flatpickr-day:focus, .flatpickr-day:hover {
    background: var(--color-primary-800);
    border-color: var(--color-primary-800);
    color: #fff;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.nextMonthDay, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.prevMonthDay {
    color: var(--color-text-tertiary);
}

.flatpickr-day.inRange {
    box-shadow: -5px 0 0 var(--color-primary-800), 5px 0 0 var(--color-primary-800);
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
    border-top-color: var(--color-background-secondary);
}

.flatpickr-calendar.noCalendar.showTimeInput.hasTime .flatpickr-time {
    border: 0;
}

.flatpickr-time input,
.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
    color: var(--color-text-primary);
}

.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
    background-color: rgba(0,0,0,.05);
}

.flatpickr-calendar.noCalendar.showTimeInput.hasTime .flatpickr-time {
    border: 0;
}

.flatpickr-calendar.noCalendar .flatpickr-time input.flatpickr-hour:focus,
.flatpickr-calendar.noCalendar .flatpickr-time input.flatpickr-hour:hover {
    border-radius: 0.25rem 0 0 0.25rem;
}

.flatpickr-calendar.noCalendar .flatpickr-time input.flatpickr-minute:focus,
.flatpickr-calendar.noCalendar .flatpickr-time input.flatpickr-minute:hover {
    border-radius: 0 0.25rem 0.25rem 0;
}
