.codemirror-box {
    border: 1px solid var(--color-background-tertiary);
    border-radius: 0.25rem;
}

.redactor-box .CodeMirror {
    border-top: 1px solid var(--color-background-tertiary);
    border-radius: 0 0 0.25rem 0.25rem;
}

.CodeMirror {
    border-radius: 0.25rem;
}

.codemirror-box .redactor-toolbar-wrapper + .CodeMirror {
    border-radius: 0 0 0.25rem 0.25rem;
}

/*
  Name:       material
  Author:     Mattia Astorino (http://github.com/equinusocio)
  Website:    https://material-theme.site/
*/

.cm-s-material.CodeMirror {
    background-color: #263238;
    color: #EEFFFF;
}

.cm-s-material .CodeMirror-gutters {
    background: #37474f;
    border: none;
}

.cm-s-material .CodeMirror-cursor {
    border-left: 1px solid #FFCC00;
}

.cm-s-material div.CodeMirror-selected {
    background: rgba(128, 203, 196, 0.2);
}

.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
    background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line>span::selection,
.cm-s-material .CodeMirror-line>span>span::selection {
    background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line>span::-moz-selection,
.cm-s-material .CodeMirror-line>span>span::-moz-selection {
    background: rgba(128, 203, 196, 0.2);
}

.cm-s-material pre.CodeMirror-line {
    box-shadow: none;
}

.cm-s-material .CodeMirror-activeline-background {
    background: rgba(0, 0, 0, 0.5);
}

.cm-s-material .cm-keyword {
    color: #C792EA;
}

.cm-s-material .cm-operator {
    color: #89DDFF;
}

.cm-s-material .cm-variable-2 {
    color: #EEFFFF;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
    color: #f07178;
}

.cm-s-material .cm-builtin {
    color: #FFCB6B;
}

.cm-s-material .cm-atom {
    color: #F78C6C;
}

.cm-s-material .cm-number {
    color: #FF5370;
}

.cm-s-material .cm-def {
    color: #82AAFF;
}

.cm-s-material .cm-string {
    color: #C3E88D;
}

.cm-s-material .cm-string-2 {
    color: #f07178;
}

.cm-s-material .cm-comment {
    color: #546E7A;
}

.cm-s-material .cm-variable {
    color: #f07178;
}

.cm-s-material .cm-tag {
    color: #FF5370;
}

.cm-s-material .cm-meta {
    color: #FFCB6B;
}

.cm-s-material .cm-attribute {
    color: #C792EA;
}

.cm-s-material .cm-property {
    color: #C792EA;
}

.cm-s-material .cm-qualifier {
    color: #DECB6B;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
    color: #DECB6B;
}

.cm-s-material .cm-error {
    color: rgba(255, 255, 255, 1.0);
    background-color: #FF5370;
}

.cm-s-material .CodeMirror-matchingbracket {
    text-decoration: underline;
    color: white !important;
}
