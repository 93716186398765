.selectize-input {
    border: 2px solid var(--color-background-secondary);
    border-radius: 0.25rem;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: var(--color-text-primary);
    line-height: inherit;
    max-width: 100%;
    overflow: inherit;
    padding: 0.375rem 0.75rem;
}

.selectize-control.single .selectize-input {
    padding-right: 1.75rem;
}

.selectize-input,
.selectize-input.full,
.selectize-control .selectize-input.disabled {
    background-color: var(--color-background-secondary);
}

.selectize-input.input-active,
.selectize-control.single .selectize-input.input-active {
    background-color: var(--color-background-primary);
}

.selectize-input.focus {
    box-shadow: none;
}

.selectize-input.has-options.dropdown-active.focus {
    border-radius: 0.25rem 0.25rem 0 0;
}

.selectize-input.dropdown-active::before {
    background: transparent;
}

.selectize-input > input {
    height: auto;
}

.selectize-dropdown, .selectize-input, .selectize-input input {
    color: var(--color-text-primary);
    font-size: inherit;
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input.input-active {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
    background-position: right 0.125rem center;
    background-repeat: no-repeat;
    background-size: 1.5rem;
}

.selectize-control.single .selectize-input:after {
    display: none;
}

.selectize-control.multi .selectize-input.has-items {
    padding: 0.375rem 0.75rem 0.125rem;
}

.selectize-control.multi .selectize-input > div {
    margin: 0 0.25rem 0.25rem 0;
    padding: 0 0.5rem;
}

.selectize-control.multi .selectize-input > div,
.selectize-control.multi .selectize-input > div.active {
    background: var(--color-background-tertiary);
    color: var(--color-text-primary);
    display: inline-block;
}

.sp-selectize-inline-block .selectize-control {
    display: block;
}

@media (min-width: 640px) {
    .sp-selectize-inline-block .selectize-control {
        display: inline-block;
        min-width: 16rem;
        vertical-align: middle;
    }
}

/* Dropdown */

.selectize-dropdown {
    background: var(--color-background-primary);
    border: 2px solid var(--color-background-secondary);
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 0;
    box-shadow: none;
    color: var(--color-text-primary);
    margin: 0;
    z-index: 10001;
}

.selectize-dropdown-content {
    border-radius: 0 0 0.25rem 0.25rem;
}

.selectize-dropdown .active {
    background: var(--color-background-secondary);
    color: var(--color-text-primary);
}

.selectize-dropdown .optgroup-header {
    background: transparent;
    color: var(--color-text-primary);
    font-weight: bold;
}

.selectize-dropdown .optgroup-header,
.selectize-dropdown .option,
.selectize-dropdown [data-selectable] {
    padding: 0.375rem 0.75rem;
}

.selectize-dropdown .create,
.selectize-dropdown .active.create {
    color: var(--color-text-secondary);
}

/* Loading/disabled */

.selectize-control.loading:before {
    background: url('../../general/img/spinner.gif');
    content: "";
    height: 2rem;
    position: absolute;
    right: 0.5rem;
    top: 0.25rem;
    width: 2rem;
    z-index: 2;
}

.sp-theme-dark .selectize-control.loading:before {
    background: url('../../general/img/spinner-dark.gif');
}

.selectize-control.single.loading:before {
    right: 1.5rem;
}

.selectize-control .selectize-input.disabled {
    opacity: 0.5;
}

/* Remove button and unremovable */

.selectize-control.plugin-remove_button [data-value] {
    padding-right: 1.625rem !important;
}

.selectize-control.plugin-remove_button [data-value] .remove,
.selectize-control.plugin-remove_button [data-value].active .remove {
    border-left-color: var(--color-background-secondary);
    font-size: 0.875rem;
    height: 100%;
    padding-top: 1px;
}

.selectize-control.plugin-remove_button [data-value].unremovable {
    padding-right: 0.5rem !important;
}

.selectize-control.plugin-remove_button [data-value].unremovable .remove {
    display: none;
}
