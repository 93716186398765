.hideShowPassword-wrapper {
    line-height: 0;
    vertical-align: inherit !important;
    width: auto !important;
}

.sp-form-full .hideShowPassword-wrapper {
    width: 100% !important;
}

.hideShowPassword-wrapper input {
    min-width: auto !important;
    padding-right: 6rem !important;
    width: 100%;
}

.hideShowPassword-toggle-show,
.hideShowPassword-toggle-hide {
    background: var(--color-background-primary);
    box-shadow: none;
    margin-top: 0 !important;
    min-width: 5rem;
    right: 0.25rem !important;
    transform: translateY(-50%);
}
