.sp-form-row.sp-input-has-error {
    display: flex;
}

.sp-input-has-error label {
    color: #e53e3e !important;
}

.sp-input-has-error input,
.sp-input-has-error select,
.sp-input-has-error .redactor-box,
.sp-input-has-error .codemirror-box,
input.sp-input-has-error,
select.sp-input-has-error {
    border-color: #e53e3e !important;
}

.sp-input-has-error .redactor-box,
.sp-input-has-error .codemirror-box {
    border-width: 2px;
}

.sp-input-has-error .sp-input-error:not(:empty),
input.sp-input-has-error ~ div > .sp-input-error:not(:empty),
select.sp-input-has-error ~ div > .sp-input-error:not(:empty),
textarea.sp-input-has-error ~ div > .sp-input-error:not(:empty) {
    background: #e53e3e;
    border-radius: 0.25rem;
    color: #fff;
    display: inline-block;
    font-size: 0.875rem;
    margin-top: 0.75rem;
    padding: 0.375rem 0.75rem;
}
